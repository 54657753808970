
.transparent{
    background-color: #0000;
}
.big-text{
    font-size: 150%;
}
.fill{
    width: 100%;
    height: 100%;
    flex-direction: columns;
}
